import React, { useEffect, useState } from 'react';
import { Home, PlusCircle } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { getContact, updateContact } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { useNavigate } from 'react-router-dom';

const ContactPage = () => {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        email: "",
        email1: "",
        email2: "",
        email3: "",
        phone: "",
        phone1: "",
        phone2: "",
        phone3: "",
        address: "",
        address1: "",
        address2: "",
        address3: "",
        location: "",
        location1: "",
        location2: "",
        location3: "",
        content: "",
        contentEN: "",
    });

    const getData = async () => {
        const category = await getContact(1);
        setFormData({
            email: category.result.email,
            email1: category.result.email1,
            email2: category.result.email2,
            email3: category.result.email3,
            phone: category.result.phone,
            phone1: category.result.phone1,
            phone2: category.result.phone2,
            phone3: category.result.phone3,
            address: category.result.address,
            address1: category.result.address1,
            address2: category.result.address2,
            address3: category.result.address3,
            location: category.result.location,
            location1: category.result.location1,
            location2: category.result.location2,
            location3: category.result.location3,
            content: category.result.content,
            contentEN: category.result.contentEN,
        })
    }

    const addData = async () => {
        var res = await updateContact(2, formData.email, formData.email1, formData.email2, formData.email3, formData.phone, formData.phone1, formData.phone2, formData.phone3, formData.address, formData.address1, formData.address2, formData.address3, formData.location, formData.location1, formData.location2, formData.location3, formData.content, formData.contentEN);
        if (res.message === "Success")
            toast.success("İletişim içeriği güncellendi.")
        else
            toast.error("İletişim içeriği güncellenirken bir sorun oluştu!")
        getData()
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>İletişim Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>İletişim Bilgileri Düzenle</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={12} className='mb-3'>
                                        <span>Email</span>
                                        <Input
                                            type='input'
                                            value={formData.email}
                                            onChange={e =>
                                                setFormData({ ...formData, email: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={12} className='mb-3'>
                                        <span>Telefon</span>
                                        <Input type='input'
                                            value={formData.phone}
                                            onChange={e =>
                                                setFormData({ ...formData, phone: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={12} className='mb-3'>
                                        <span>FAX</span>
                                        <Input type='input'
                                            value={formData.phone2}
                                            onChange={e =>
                                                setFormData({ ...formData, phone2: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={12} className='mb-3'>
                                        <span>Adres</span>
                                        <Input type='textarea'
                                            value={formData.address}
                                            onChange={e =>
                                                setFormData({ ...formData, address: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        <Button
                                            color='warning'
                                            onClick={() => addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />Güncelle
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default ContactPage;
