import React, { useEffect, useState } from 'react';
import { Edit2, Home, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Select, Button as Button2 } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { createCategory, deleteCategory, getCategory, updateCategory, getAllCategories, getAllImageGroupsAll } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { useNavigate } from 'react-router-dom';

const CategoryPage = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [multipleLoad, setMultipleLoad] = useState(false)
    const [imageGroups, setImageGroups] = useState([]);
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        nameEN: "",
        content: "",
        contentEN: "",
        imageGroupID: null,
        parentCategoryId: null,
    });

    const clearForm = () => {
        setFormData({ name: "", nameEN: "", content: "", contentEN: "", imageGroupID: null, parentCategoryId: null })
        setSelectedData(null)
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getDatas = async () => {
        const imageGroups = await getAllImageGroupsAll();
        const categories = await getAllCategories();
        if (categories.message === "Success")
            setData(categories.result);

        if (imageGroups.message === "Success") {
            var newlist = []
            for (var i = 0; i < imageGroups.result.length; i++) {
                if (imageGroups.result[i] !== undefined)
                    newlist.push({ value: imageGroups.result[i].imageGroupID, label: imageGroups.result[i].title });
            }
            setImageGroups(newlist);
        }
        if (categories.message === "Success") {
            var newlist = []
            for (var i = 0; i < categories.result.length; i++) {
                if (categories.result[i] !== undefined)
                    newlist.push({ value: categories.result[i].categoryId, label: categories.result[i].name });
            }
            setCategories(newlist);
        }
        setTimeout(() => {
            setLoading(true)
        }, 1000);
    };

    const getData = async (id) => {
        const category = await getCategory(id);
        setSelectedData(id)
        setFormData({ name: category.result.name, nameEN: category.result.nameEN, imageGroupID: category.result.imageGroupID, parentCategoryId: category.result.parentCategoryId })
    }

    const addData = async () => {
        let res;
        if (selectedData) {
            res = await updateCategory(selectedData, formData.name, formData.nameEN, formData.imageGroupID, formData.parentCategoryId);
        } else {
            res = await createCategory(formData.name, formData.nameEN, formData.imageGroupID, formData.parentCategoryId);
        }
        if (res.message === "Success")
            toast.success("Kategori içeriği eklendi.")
        else
            toast.error("Kategori içeriği eklenirken bir sorun oluştu!")
        getDatas()
        clearForm()
    }

    const deleteData = async (id) => {
        await deleteCategory(id);
        toast.success("Kategori içeriği silindi.")
        clearForm()
        getDatas()
    }

    const multipleDelete = async () => {
        setMultipleLoad(true)
        if (selectedRowKeys.length > 0) {
            for (var i = 0; i < selectedRowKeys.length; i++) {
                await deleteCategory(selectedRowKeys[i]);
            }
            await getDatas()
        }
        setMultipleLoad(false)
    }

    const columns = [
        {
            title: "ID",
            key: "categoryId",
            dataIndex: "categoryId"
        },
        {
            title: "Başlık",
            key: "name",
            render: (e) => e.name
        },
        // {
        //     title: "Description",
        //     key: "shortDescription",
        //     render: (e) => e.shortDescription
        // },
        {
            title: "Resim Grubu",
            key: "imageGroupID",
            render: (e) => e.imageGroup === null ? "" : e.imageGroup.title
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div>
                        <Button2 className='border-warning text-warning' icon={<Edit2 size={20} />} onClick={() => getData(e.categoryId)} />
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu kategori içeriğini silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.categoryId)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button2 icon={<Trash2 size={20} />} danger />
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Category Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button color='info' onClick={() => navigate("/image")}><PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />Resim Grubu Ekle</Button>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Category Panosu Ekle</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={6} className='mb-3'>
                                        <span>Kategori Adı</span>
                                        <Input
                                            type='input'
                                            value={formData.name}
                                            onChange={e =>
                                                setFormData({ ...formData, name: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Kategori Adı (EN)</span>
                                        <Input type='input'
                                            value={formData.nameEN}
                                            onChange={e =>
                                                setFormData({ ...formData, nameEN: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Resim Grubu</span>
                                        <Select
                                            options={imageGroups}
                                            className='w-100'
                                            style={{ height: 35 }}
                                            value={formData.imageGroupID}
                                            onChange={e => setFormData({ ...formData, imageGroupID: e })}
                                        />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Üst Kategori</span>
                                        <Select
                                            options={categories}
                                            className='w-100'
                                            style={{ height: 35 }}
                                            value={formData.parentCategoryId}
                                            onChange={e => setFormData({ ...formData, parentCategoryId: e })}
                                        />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {selectedData ? <Button onClick={() => clearForm()} className='bg-white border-warning text-warning'>Temizle</Button> : null}
                                        <Button
                                            color={selectedData ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{selectedData ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Category Panosu Listesi</h4></div>
                                {selectedRowKeys.length > 0 ? <div><Button onClick={multipleDelete} className='d-flex justify-content-center text-white' color='danger'><Trash2 size={20} style={{ marginTop: 3, marginRight: 7 }} /> {multipleLoad ? <Spinner style={{ width: 23, height: 23 }} color='white'> </Spinner> : "Toplu Sil"}</Button></div> : null}
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='categoryId'
                                    dataSource={data}
                                    rowSelection={rowSelection}
                                    columns={columns}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default CategoryPage;
